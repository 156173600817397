<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
import FormPage from '../form/form';

export default {
  extends: TablePage,
  components: {
    FormPage,
  },
  data() {
    return {
      formShow: false,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('order-anxious-list');
  },

  methods: {
    modalClick({ val, row }) {
      const formPageArg = {
        drawerTitle: '',
        code: val.code, // 按钮对应code
        id: '', // 数据id
        formShow: true,
      };
      switch (val.code) {
        case 'add':
          formPageArg.drawerTitle = '新增';
          break;
        case 'view':
          formPageArg.drawerTitle = '查看';
          break;
        case 'edit':
          formPageArg.drawerTitle = '编辑';
          break;
        default:
          formPageArg.drawerTitle = '新增';
      }
      if (val.code === 'view' || val.code === 'edit') {
        formPageArg.id = row.id;
      }
      this.createFormPage(formPageArg);
    },
    createFormPage(arg) {
      const Component = Vue.extend(FormPage);
      const that = this;
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('order-anxious-list');
          },
        },
      });
      document.body.appendChild(vm.$el);
    },
  },
};
</script>
